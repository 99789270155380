import React, { useState } from "react";

import Fade from "react-reveal";

import { saveAs } from "file-saver";
import { Button } from "semantic-ui-react";

export const About = (props) => {
  const [showLoader, setShowLoader] = useState(false);

  // App.js sayfasından gönderdiğim verilerin undifine | null olma durumlarına karşılık sayfanın boş dönmesini sağlıyoruz herhangi bir hata mesajı almayalım
  if (!props.data) return null;

  // App.js sayfasından gönderdiğim verileri parçalayarak alıyorum
  const { profileImage, bio } = props.data;

  const profilepic = "assets/images/" + profileImage;

  // file-saver kütüphanesini kullanarak verilen link ile özgeçmişimin olduğu sayafaya yönlendiriyorum.
  const saveFile = () => {
    setShowLoader(true);
    saveAs(
      "https://drive.google.com/file/d/1G5u_OjrJM-42Clq2T8ICgXx_XeqhJ-dl/view",
      "frkinalcv.pdf"
    );
    setTimeout(() => setShowLoader(false), 2000);
  };

  return (
    <section id="about">
      {/* Animasyon için react-reveal kütüphanesinden Fade componenti ile sayfayı kapsadım */}
      <Fade duration={1000}>
        <div className="row">
          <div className="three columns">
            {/* images klasörüne kaydeetiğim kişisel fotoğrafımı ekrana bastım */}
            <img
              className="profile-pic"
              src={require("../../" + profilepic)}
              alt="frkinal"
            />
          </div>
          <div className="nine columns main-col">
            <h2>About Me</h2>
            {/* resumeData json dosyasına kaydettiğim bio verisini ekrana yazdırıyorum*/}
            <p>{bio}</p>
            <div className="row">
              <div className="columns download">
                {/* resumeData json dosyasına kaydettiğim resumeDownload linkini ziyaretçiler cv me ulaşabilsin diye link yönlendirmesi ekledim. */}
                <Button
                  onClick={saveFile}
                  secondary
                  className="submit-btn"
                  loading={showLoader}
                  disabled={showLoader}
                >
                  <i className="fa fa-download"></i>Download Resume
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};
