import React from "react";

import Slide from "react-reveal";

export const Resume = (props) => {
  // App.js sayfasından gönderdiğim verilerin undifine | null olma durumlarına karşılık sayfanın boş dönmesini sağlıyoruz herhangi bir hata mesajı almayalım
  if (!props.data) return null;

  // App.js sayfasından gönderdiğim verileri parçalayarak alıyorum
  const { skills, work } = props.data;

  // Burda skills kısmı için random renk kodu oluşturuyorum
  const getRandomColor = () => {
    let letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  return (
    <section id="resume">
      {/* Animasyon için react-reveal kütüphanesinden Fade componenti ile kullandığım componenti kapsadım */}
      <Slide left duration={1300}>
        <div className="row work">
          <div className="three columns header-col">
            <h1>
              <span>Work</span>
            </h1>
          </div>

          <div className="nine columns main-col">
            {/* resumeData json dosyasinda kaydettiğim iş deneyimlerimi ekrana yazdırıyorum. */}
            {work.map((item) => {
              return (
                <div key={item.company}>
                  <h3>{item.company}</h3>
                  <p className="info">
                    {item.title}
                    <span>&bull;</span> <em className="date">{item.years}</em>
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </Slide>
      {/* Animasyon için react-reveal kütüphanesinden Fade componenti ile kullandığım componenti kapsadım */}
      <Slide left duration={1300}>
        <div className="row skill">
          <div className="three columns header-col">
            <h1>
              <span>Skills</span>
            </h1>
          </div>
          <div className="nine columns main-col">
            <div className="bars">
              <ul className="skills">
                {/* resumeData json dosyasinda kaydettiğim yeteneklerimi oluşturdugum gerRandomColor fonksiyonu ile ekrana yazdırıyorum. */}
                {skills.map((item) => {
                  const backgroundColor = getRandomColor();
                  const className = "bar-expand " + item.name.toLowerCase();
                  const width = item.level;
                  return (
                    <li key={item.name}>
                      <span
                        style={{ width, backgroundColor }}
                        className={className}
                      ></span>
                      <em>{item.name}</em>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </Slide>
    </section>
  );
};
