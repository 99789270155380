import React from "react";
import ParticlesBg from "particles-bg";
import Fade from "react-reveal";

export const Home = (props) => {
  // App.js sayfasından gönderdiğim verilerin undifine | null olma durumlarına karşılık sayfanın boş dönmesini sağlıyoruz herhangi bir hata mesajı almayalım
  if (!props.data) return null;

  // App.js sayfasından gönderdiğim verileri parçalayarak alıyorum
  const { linkedin, github, name, description } = props.data;

  return (
    <header id="home">
      <ParticlesBg type="circle" bg={true} />
      <nav id="nav-wrap">
        <a className="mobile-btn" href="#nav-wrap" title="Show navigation">
          Show navigation
        </a>
        <a className="mobile-btn" href="#home" title="Hide navigation">
          Hide navigation
        </a>
        <ul id="nav" className="nav">
          <li className="current">
            <a className="smoothscroll" href="#home">
              Home
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#about">
              About
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#resume">
              Resume
            </a>
          </li>
          <li>
            <a className="smoothscroll" href="#contact">
              Contact
            </a>
          </li>
        </ul>
      </nav>

      <div className="row banner">
        <div className="banner-text">
          {/* Animasyon için react-reveal kütüphanesinden Fade componenti ile kullandığım componenti kapsadım */}
          <Fade bottom>
            <h1 className="responsive-headline">{name}</h1>
          </Fade>
          {/* Animasyon için react-reveal kütüphanesinden Fade componenti ile kullandığım componenti kapsadım */}
          <Fade bottom duration={1200}>
            <h3>{description}.</h3>
          </Fade>
          <hr />
          {/* Animasyon için react-reveal kütüphanesinden Fade componenti ile kullandığım componenti kapsadım */}
          <Fade bottom duration={2000}>
            <ul className="social">
              <a href={linkedin} className="button btn linkedin-btn">
                <i className="fa fa-linkedin"></i>Linkedin
              </a>
              <a href={github} className="button btn github-btn">
                <i className="fa fa-github"></i>Github
              </a>
            </ul>
          </Fade>
        </div>
      </div>
      <p className="scrolldown">
        <a className="smoothscroll" href="#about">
          <i className="icon-down-circle"></i>
        </a>
      </p>
    </header>
  );
};
