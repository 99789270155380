import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "./App.css";
import { About, Contact, Home, Resume } from "./screens";
import { Footer } from "./components";

const App = () => {
  const [resumeData, setResumeData] = useState([]);

  ReactGA.initialize("UA-110570651-1");
  ReactGA.pageview(window.location.pathname);

  const getResumeData = () => {
    $.ajax({
      url: "./resumeData.json",
      dataType: "json",
      cache: false,
      success: (data) => {
        setResumeData(data);
      },
      error: (err) => {
        console.log(err);
        alert(err);
      },
    });
  };

  useEffect(() => {
    getResumeData();
  }, []);

  return (
    <div className="App">
      <Home data={resumeData.main} />
      <About data={resumeData.main} />
      <Resume data={resumeData.resume} />
      <Contact data={resumeData.main} />
      <Footer data={resumeData.main} />
    </div>
  );
};

export default App;
