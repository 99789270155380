import React, { useState } from "react";
import { Fade, Slide } from "react-reveal";
import { Form, Input, TextArea, Button, Message } from "semantic-ui-react";
import emailjs from "emailjs-com";

// emailjs ile mail gönderebilmek için emailjs tarafından bana veriile id leri tanımlıyorum
const SERVICE_ID = "service_weg34wq";
const TEMPLATE_ID = "template_cfl8qu9";
const USER_ID = "F-ElKO-cZKpmorJ87";

export const Contact = (props) => {
  const [status, setStatus] = useState("");
  const [emailValid, setEmailValid] = useState(true);

  // App.js sayfasından gönderdiğim verilerin undifine | null olma durumlarına karşılık sayfanın boş dönmesini sağlıyoruz herhangi bir hata mesajı almayalım
  if (!props.data) return null;

  // resumdataJSON dosyasnıda kaydettiğim message verisini çekiyorum
  const { contactMessage, contactImage } = props.data;

  const contactpic = "assets/images/" + contactImage;

  // E-Mail validasyon işlemiyle birlikte, emailjs kütüphanesinden yararlanarak kullanıcıların benimle E-Mail yoluyla iletişime geçmesini sağlıyorum.
  const handleOnSubmit = (e) => {
    const regex = /\S+@\S+\.\S+/;
    e.preventDefault();
    setStatus("loading");
    if (!regex.test(e.target.user_email.value)) {
      setEmailValid(false);
      setStatus("error");
    } else {
      setEmailValid(true);
      emailjs
        .sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
        .then(() => {
          setStatus("success");
          setTimeout(() => {
            setStatus("");
          }, 3000);
        })
        .catch(() => {
          setStatus("error");
          setTimeout(() => {
            setStatus("");
          }, 3000);
        });
      e.target.reset();
    }
  };

  return (
    <section id="contact">
      {/* Animasyon için react-reveal kütüphanesinden Fade componenti ile kullandığım componenti kapsadım */}
      <Fade bottom duration={1000}>
        <div className="row section-head">
          <div className="eight columns contactHeader">
            <div className="two columns header-col">
              <h1>
                <span>Get In Touch.</span>
              </h1>
            </div>
            <div className="ten columns">
              {/* resumeData json dosyasına kaydettiğim message verisini ekrana yazdırıyorum*/}
              <p className="lead">{contactMessage}</p>
            </div>
          </div>
        </div>
      </Fade>

      <div className="row">
        {/* Animasyon için react-reveal kütüphanesinden Fade componenti ile kullandığım componenti kapsadım */}
        <Slide left duration={1000}>
          {/* semantic-ui-react kütüphanesinde yararlanarak bir form oluşturuyrum */}
          <div className="seven columns">
            <Form
              loading={status === "loading"}
              error={status === "error"}
              success={status === "success"}
              widths="equal"
              onSubmit={handleOnSubmit}
            >
              <Form.Group>
                <Form.Field
                  id="form-input-control-first-name"
                  control={Input}
                  name="from_name"
                  placeholder="Name…"
                  required
                />
                <Form.Field
                  id="form-input-control-last-name"
                  control={Input}
                  name="from_surname"
                  placeholder="Surname…"
                  required
                />
              </Form.Group>
              <Form.Field
                id={
                  !emailValid
                    ? "form-input-control-error-email"
                    : "form-input-control-email"
                }
                control={Input}
                name="user_email"
                placeholder="Email…"
                error={
                  !emailValid && {
                    content: "Please enter a valid email address",
                    pointing: "below",
                  }
                }
                required
              />
              <Form.Field
                id="form-textarea-control-opinion"
                control={Input}
                placeholder="Opinion..."
                required
              />
              <Form.Field
                id="form-textarea-control-opinion"
                control={TextArea}
                className="messageInput"
                name="user_message"
                placeholder="Message…"
                required
              />
              <Button type="submit" secondary>
                Submit
              </Button>
              {status === "success" || status === "error" ? (
                <Message
                  success={status === "success"}
                  error={status === "error"}
                  header={status === "success" ? "Success" : "Error"}
                  content={
                    status === "success"
                      ? "Message Sent Successfully"
                      : !emailValid
                      ? "Please enter a valid email address"
                      : "Ooops, something went wrong"
                  }
                />
              ) : null}
            </Form>
          </div>
          <div className="five columns contactContainer">
            <img src={require("../../" + contactpic)} alt="contact" />
          </div>
        </Slide>
      </div>
    </section>
  );
};
