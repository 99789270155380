import React from "react";
import Fade from "react-reveal";

export const Footer = (props) => {
  // App.js sayfasından gönderdiğim verilerin undifine | null olma durumlarına karşılık sayfanın boş dönmesini sağlıyoruz herhangi bir hata mesajı almayalım
  if (!props.data) return null;
  const { social, linkedin } = props.data;

  return (
    <footer>
      <div className="row">
        {/* Animasyon için react-reveal kütüphanesinden Fade componenti ile sayfayı kapsadım */}
        <Fade bottom>
          <div className="twelve columns">
            <ul className="social-links">
              {/* resumeData json dosyasinda kaydettiğim kişisel sosyal medya hesap linklerimi ekrana yazdırıyorum ve yönlendirmelerini yapıyorum */}
              {social.map((item) => {
                return (
                  <li key={item.name}>
                    <a href={item.url} target="_blank">
                      <i className={item.className}></i>
                    </a>
                  </li>
                );
              })}
            </ul>

            <ul className="copyright">
              <li>&copy; Copyright 2023</li>
              <li>
                Design by
                <a title="Styleshout" href={linkedin} target="_blank">
                  {" "}
                  frkinal
                </a>
              </li>
            </ul>
          </div>
        </Fade>
        {/* En başa dönmek için icon a etiketleri arasına icon ekledim */}
        <div id="go-top">
          <a className="smoothscroll" title="Back to Top" href="#home">
            <i className="icon-up-open"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};
